<template>
  <div class="app_con">
    <app-list
      ref="list"
      :remote="remote"
      :props="props"
      :form-props="formProps"
      :api-name="apiName"
      :edit-title="'检测信息'"
      dialog-width="1000px"
      :rules="rules"
    >
      <template #tool>
        <el-upload
          :headers="headers"
          action="/api/safetyTrace/detectInfo/importExcel"
          :show-file-list="false"
          :on-success="onSuccess"
          :on-error="onError"
          style="display: inline-flex; margin-right: 10px;"
        >
          <el-button icon="el-icon-upload" accept=".xls,.xlsx"
            >上传检测信息</el-button
          >
        </el-upload>
        <el-button @click="onDownloadTemplate">下载模板</el-button>
      </template>
    </app-list>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth';
import api from '@/api';

export default {
  data() {
    return {
      headers: { token: getToken() },
      remote: api.common,
      apiName: '/safetyTrace/detectInfo',
      rules:{
        merchantPersonnelId:[{ required: true, message: "请选择商户名称", trigger: "change" }],
        detectDate:[{ required: true, message: "请选择日期", trigger: "change" },]        
      },
      props: [
        {
          label: '商户',
          prop: 'merchantPersonnelName'
        },
        {
          label: '商品类别',
          prop: 'commoditTypeName'
        },
        {
          label: '商品名称',
          prop: 'commoditName'
        },
        {
          label: '数量',
          prop: 'quantity'
        },
        {
          label: '检测项目',
          prop: 'detectProjectName'
        },
        {
          label: '检测结果',
          prop: 'detectResult',
          formatter(row) {
            return row.detectResult == 0 ? '未检出' : '检出';
          }
        },
        {
          label: '检测日期',
          prop: 'detectDate'
        }
      ],
      formProps: [
        {
          label: '商户名称',
          prop: 'merchantPersonnelId',
          type: 'select',
          selectData: []
        },
        { label: '检测日期', prop: 'detectDate', type: 'date' },
        {
          label: '检测项目',
          prop: 'commodityList',
          type: 'multi-line',
          isFull: true,
          props: [
            {
              label: '商品类别',
              prop: 'commoditTypeId',
              type: 'select',
              placeholder: '请选择商品类别',
              options: []
            },
            {
              label: '商品名称',
              prop: 'commoditName',
              type: 'input',
              placeholder: '请输入商品名称'
            },
            {
              label: '检测项目',
              prop: 'detectProjectId',
              type: 'select',
              placeholder: '请选择检测项目',
              options: []
            },
            {
              label: '数量',
              prop: 'quantity',
              type: 'input',
              placeholder: '请输入数量'
            },
            {
              label: '检测结果',
              prop: 'detectResult',
              type: 'select',
              placeholder: '请选择结果',
              options: [
                { label: '未检出', value: 0 },
                { label: '检出', value: 1 }
              ]
            }
          ]
        }
      ]
    };
  },
  async created() {
    await this.getMerchants();
    await this.getTypes();
    await this.getProjects();
  },
  methods: {
    async getMerchants() {
      const result = await api.common.list({
        apiName: '/merchant/personnel',
        pageSize: 1000
      });
      const options = result.data.data.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      this.formProps[0].selectData = options;
    },
    async getTypes() {
      const result = await api.dictionary.getAllList({
        type: '检测信息-商品类别'
      });
      this.formProps[2].props[0].options = result.data;
    },
    async getProjects() {
      const result = await api.dictionary.getAllList({
        type: '检测信息-检测项目'
      });
      this.formProps[2].props[2].options = result.data;
    },
    async onDownloadTemplate() {
      await api.common.download({
        apiName: '/safetyTrace/detectInfo/exportExcelTemplate'
      });
    },
    onSuccess() {
      this.$refs.list.getItems();
    },
    onError(err) {
      const res = JSON.parse(err.message);
      this.$message({
        message: res.message,
        type: 'error'
      });
    }
  }
};
</script>
